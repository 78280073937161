import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PortalScope } from '../../login/login.component';
import { ApplicationForUser } from '../../models/borrower-portal/application-for-user-model';
import { ContactInfoModel } from '../../models/borrower-portal/contact-info-model';
import { BasePortalService } from '../../services/base-portal.service';
import { DrawerOptions, DrawerSize } from '../../services/drawer.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { NotificationService } from '../../services/notification.service';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { ApplicationTaskModel, BorrowerTaskType } from '../../models/borrower-portal/application-task-model';
import { PortalTaskInformationComponent } from '../task-information/task-information.component';
import { firstValueFrom } from 'rxjs';
import { LoanActions } from '../loan-actions/loan-actions.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class PortalLoanDetailsComponent implements OnInit {

  @ViewChild('tasksView')
  tasksView: PortalTaskInformationComponent;

  loanId: number;
  application: ApplicationForUser;

  agentRequestACallDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  borrowerContact: ContactInfoModel;

  agentAddressHidden: boolean = true;
  isAgent: boolean = false;

  protected actionsApplicableToLoan: LoanActions;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _portalService: BasePortalService,
    private readonly _router: Router,
    private readonly _localStorage: LocalStorageService,
    private readonly _notifsService: NotificationService,
    private readonly _environment: EnvironmentService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _activatedRoute: ActivatedRoute) {

    let userScopeFromPath = null;
    if (this._activatedRoute.routeConfig && this._activatedRoute.routeConfig.data && this._activatedRoute.routeConfig.data.scope) {
      userScopeFromPath = this._activatedRoute.routeConfig.data.scope;
    }

    let userScopeFromLocalStorage = this._localStorage.authorizationData.scope as PortalScope;

    let userScope = this._environment.scope || userScopeFromPath || userScopeFromLocalStorage;

    if (userScope && userScope == PortalScope.Agent) {
      this.isAgent = true;
    }
  }

  ngOnInit(): void {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.loanId = +params.get('loanId');
      this.loadApplicationDetails(this.loanId);
      this.loadBorrowerContactInfo(this.loanId);
    });
  }

  protected onOpenAssetVerificationClicked = () => {
    this.tasksView?.openDigitalVerificationDialog(BorrowerTaskType.DigitalAssetVerification);
  }

  protected onOpenIncomeVerificationClicked = () => {
    this.tasksView?.openDigitalVerificationDialog(BorrowerTaskType.DigitalIncomeEmploymentVerification);
  }

  protected onTasksLoaded = async (pendingTasks: ApplicationTaskModel[]) => {
    await this.setupActionsAppropriateForLoan(pendingTasks);
  }

  protected onAdditionalDocumentsAddedToLoan = () => {
    this.tasksView.reloadTasks(true);
  }

  loadApplicationDetails = (appId: number) => {
    this._portalService.getLoanDataForAppId(appId).subscribe({
      next: (application) => {
        this.application = { ...application };
      }, error: (err) => {
        const errorMessage = err?.message || "An error occurred loading loan data";
        this._notifsService.showError(errorMessage, "Error");
      }
    })
  }

  loadBorrowerContactInfo = (appId: number) => {
    this._portalService.getContactInfoForApplication(appId).subscribe(borrowerContactInfo => {
      this.borrowerContact = borrowerContactInfo;
    });
  }

  openAgentContact = () => {
    this._router.navigate([`${this.isAgent ? 'agent' : 'borrower'}-portal/request-a-call`], { queryParams: { loanId: this.loanId } });
  }

  private setupActionsAppropriateForLoan = async (pendingTasks: ApplicationTaskModel[]) => {

    const actionsAppropriateForLoan = new LoanActions();

    let hasIncomeVerificationTasks = false;
    let hasAssetVerificationTasks = false;
    if (!this.isAgent) {
      hasAssetVerificationTasks = pendingTasks.some(task => task.borrowerTaskType == BorrowerTaskType.DigitalAssetVerification);
      hasIncomeVerificationTasks = pendingTasks.some(task => task.borrowerTaskType == BorrowerTaskType.DigitalIncomeEmploymentVerification);
    }

    actionsAppropriateForLoan.isMortgageCalculatorEnabled = this.application.companyId != 252;
    this.actionsApplicableToLoan = actionsAppropriateForLoan;

    try {
        const portalConfig = await firstValueFrom(this._portalService.getPortalContent());
        this.actionsApplicableToLoan.hasAssetVerificationTasks = this.application.isAssetVerificationLoanActionEnabled && hasAssetVerificationTasks;
        this.actionsApplicableToLoan.hasIncomeVerificationTasks = this.application.isEmploymentIncomeVerificationLoanActionEnabled && hasIncomeVerificationTasks;
        this.actionsApplicableToLoan.isMortgageCalculatorEnabled = !!this.application.isMortgageCalculatorLoanActionEnabled;
        this.actionsApplicableToLoan.isUploadingAdditionalDocsEnabled = !!this.application.isUploadingAdditionalDocumentsLoanActionEnabled;
        this.actionsApplicableToLoan.hasPreApprovalLetter = !!this.application.isPreApprovalLetterGenerationLoanActionEnabled;
        if (Object.values(this.actionsApplicableToLoan).every(value => value === false)) {
          this.actionsApplicableToLoan = null;
        }
      } catch (error) {
      if (error.error !== 'Prequal detail was not found') {
        this._notifsService.showError("An error occurred loading loan actions", "Error");
      }
    }
    this._spinner.hide();
  }
}
